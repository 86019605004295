/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const initTenant = /* GraphQL */ `
  mutation InitTenant($tenant: InitTenantInput) {
    initTenant(tenant: $tenant)
  }
`;
export const updateTenantSystemId = /* GraphQL */ `
  mutation UpdateTenantSystemId($systemId: Int) {
    updateTenantSystemId(systemId: $systemId)
  }
`;
export const initMenu = /* GraphQL */ `
  mutation InitMenu($menu: InitMenuInput!) {
    initMenu(menu: $menu)
  }
`;
export const updateMenuConfigs = /* GraphQL */ `
  mutation UpdateMenuConfigs($menuConfigs: [InitMenuConfigInput]) {
    updateMenuConfigs(menuConfigs: $menuConfigs)
  }
`;
export const createJob = /* GraphQL */ `
  mutation CreateJob($createJob: JobInput) {
    createJob(createJob: $createJob)
  }
`;
export const updateJob = /* GraphQL */ `
  mutation UpdateJob($updateJob: JobInput) {
    updateJob(updateJob: $updateJob)
  }
`;
export const deleteJob = /* GraphQL */ `
  mutation DeleteJob($id: Int) {
    deleteJob(id: $id)
  }
`;
export const sendTags = /* GraphQL */ `
  mutation SendTags($job: JobInput) {
    sendTags(job: $job)
  }
`;
export const createJobDetail = /* GraphQL */ `
  mutation CreateJobDetail($createJobDetail: JobDetailInput) {
    createJobDetail(createJobDetail: $createJobDetail)
  }
`;
export const updateJobDetail = /* GraphQL */ `
  mutation UpdateJobDetail($updateJobDetail: JobDetailInput) {
    updateJobDetail(updateJobDetail: $updateJobDetail)
  }
`;
export const deleteJobDetail = /* GraphQL */ `
  mutation DeleteJobDetail($id: Int) {
    deleteJobDetail(id: $id)
  }
`;
export const createItemMaster = /* GraphQL */ `
  mutation CreateItemMaster($createItemMaster: AWSJSON) {
    createItemMaster(createItemMaster: $createItemMaster)
  }
`;
export const updateItemMaster = /* GraphQL */ `
  mutation UpdateItemMaster($updateItemMaster: AWSJSON) {
    updateItemMaster(updateItemMaster: $updateItemMaster)
  }
`;
export const updateItemMasterBySystemId = /* GraphQL */ `
  mutation UpdateItemMasterBySystemId(
    $updateItemMasterBySystemId: ItemMasterInput
  ) {
    updateItemMasterBySystemId(
      updateItemMasterBySystemId: $updateItemMasterBySystemId
    )
  }
`;
export const deleteItemMaster = /* GraphQL */ `
  mutation DeleteItemMaster($id: Int) {
    deleteItemMaster(id: $id)
  }
`;
export const updateStocks = /* GraphQL */ `
  mutation UpdateStocks($stockList: [AWSJSON]) {
    updateStocks(stockList: $stockList)
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $username: String
    $password: String
    $email: String
    $description: String
    $groupname: String
  ) {
    createUser(
      username: $username
      password: $password
      email: $email
      description: $description
      groupname: $groupname
    )
  }
`;
export const changeUserPassword = /* GraphQL */ `
  mutation ChangeUserPassword($username: String, $password: String) {
    changeUserPassword(username: $username, password: $password)
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser($username: String) {
    deleteUser(username: $username)
  }
`;
export const generateAutoNumber = /* GraphQL */ `
  mutation GenerateAutoNumber($numberKey: String) {
    generateAutoNumber(numberKey: $numberKey)
  }
`;
export const epcPublish = /* GraphQL */ `
  mutation EpcPublish($conditions: AWSJSON) {
    epcPublish(conditions: $conditions)
  }
`;
export const deleteStocks = /* GraphQL */ `
  mutation DeleteStocks($conditions: AWSJSON) {
    deleteStocks(conditions: $conditions)
  }
`;
export const updateCustomerMaster = /* GraphQL */ `
  mutation UpdateCustomerMaster($tableName: String, $master: AWSJSON) {
    updateCustomerMaster(tableName: $tableName, master: $master)
  }
`;
export const deleteCustomerMaster = /* GraphQL */ `
  mutation DeleteCustomerMaster($tableName: String, $master: AWSJSON) {
    deleteCustomerMaster(tableName: $tableName, master: $master)
  }
`;
export const updateMasterList = /* GraphQL */ `
  mutation UpdateMasterList($tableName: String, $masterList: [AWSJSON]) {
    updateMasterList(tableName: $tableName, masterList: $masterList)
  }
`;
export const epcPublishRegister = /* GraphQL */ `
  mutation EpcPublishRegister($conditions: AWSJSON) {
    epcPublishRegister(conditions: $conditions)
  }
`;
export const createItemDefine = /* GraphQL */ `
  mutation CreateItemDefine(
    $input: CreateItemDefineInput!
    $condition: ModelItemDefineConditionInput
  ) {
    createItemDefine(input: $input, condition: $condition) {
      tenantId
      source
      name
      inputType
      items
      sourceTable
      showOnApp
      searchOnApp
      order
      group
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const updateItemDefine = /* GraphQL */ `
  mutation UpdateItemDefine(
    $input: UpdateItemDefineInput!
    $condition: ModelItemDefineConditionInput
  ) {
    updateItemDefine(input: $input, condition: $condition) {
      tenantId
      source
      name
      inputType
      items
      sourceTable
      showOnApp
      searchOnApp
      order
      group
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const deleteItemDefine = /* GraphQL */ `
  mutation DeleteItemDefine(
    $input: DeleteItemDefineInput!
    $condition: ModelItemDefineConditionInput
  ) {
    deleteItemDefine(input: $input, condition: $condition) {
      tenantId
      source
      name
      inputType
      items
      sourceTable
      showOnApp
      searchOnApp
      order
      group
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const createUserManage = /* GraphQL */ `
  mutation CreateUserManage(
    $input: CreateUserManageInput!
    $condition: ModelUserManageConditionInput
  ) {
    createUserManage(input: $input, condition: $condition) {
      tenantId
      username
      menuId
      accessLevel
      group
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserManage = /* GraphQL */ `
  mutation UpdateUserManage(
    $input: UpdateUserManageInput!
    $condition: ModelUserManageConditionInput
  ) {
    updateUserManage(input: $input, condition: $condition) {
      tenantId
      username
      menuId
      accessLevel
      group
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserManage = /* GraphQL */ `
  mutation DeleteUserManage(
    $input: DeleteUserManageInput!
    $condition: ModelUserManageConditionInput
  ) {
    deleteUserManage(input: $input, condition: $condition) {
      tenantId
      username
      menuId
      accessLevel
      group
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAppItemConfig = /* GraphQL */ `
  mutation CreateAppItemConfig(
    $input: CreateAppItemConfigInput!
    $condition: ModelAppItemConfigConditionInput
  ) {
    createAppItemConfig(input: $input, condition: $condition) {
      tenantId
      id
      screenId
      source
      name
      sourceDefine {
        tenantId
        source
        name
        inputType
        items
        sourceTable
        showOnApp
        searchOnApp
        order
        group
        updatedAt
        createdAt
        __typename
      }
      order
      group
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const updateAppItemConfig = /* GraphQL */ `
  mutation UpdateAppItemConfig(
    $input: UpdateAppItemConfigInput!
    $condition: ModelAppItemConfigConditionInput
  ) {
    updateAppItemConfig(input: $input, condition: $condition) {
      tenantId
      id
      screenId
      source
      name
      sourceDefine {
        tenantId
        source
        name
        inputType
        items
        sourceTable
        showOnApp
        searchOnApp
        order
        group
        updatedAt
        createdAt
        __typename
      }
      order
      group
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const deleteAppItemConfig = /* GraphQL */ `
  mutation DeleteAppItemConfig(
    $input: DeleteAppItemConfigInput!
    $condition: ModelAppItemConfigConditionInput
  ) {
    deleteAppItemConfig(input: $input, condition: $condition) {
      tenantId
      id
      screenId
      source
      name
      sourceDefine {
        tenantId
        source
        name
        inputType
        items
        sourceTable
        showOnApp
        searchOnApp
        order
        group
        updatedAt
        createdAt
        __typename
      }
      order
      group
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const createMenu = /* GraphQL */ `
  mutation CreateMenu(
    $input: CreateMenuInput!
    $condition: ModelMenuConditionInput
  ) {
    createMenu(input: $input, condition: $condition) {
      tenantId
      id
      showOrder
      name
      icon
      functionId
      stockStatus
      jobStep
      locationFlag
      sendFinishFlag
      unexpectedTagFlag
      mapMode
      pickingRssi
      settings
      showDeleteButton
      showSide
      configs {
        items {
          tenantId
          id
          menuId
          name
          source
          searchOrder
          listOrder
          detailOrder
          group
          updatedAt
          createdAt
          __typename
        }
        nextToken
        __typename
      }
      properties {
        items {
          tenantId
          id
          menuId
          name
          inputType
          items
          group
          updatedAt
          createdAt
          __typename
        }
        nextToken
        __typename
      }
      group
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const updateMenu = /* GraphQL */ `
  mutation UpdateMenu(
    $input: UpdateMenuInput!
    $condition: ModelMenuConditionInput
  ) {
    updateMenu(input: $input, condition: $condition) {
      tenantId
      id
      showOrder
      name
      icon
      functionId
      stockStatus
      jobStep
      locationFlag
      sendFinishFlag
      unexpectedTagFlag
      mapMode
      pickingRssi
      settings
      showDeleteButton
      showSide
      configs {
        items {
          tenantId
          id
          menuId
          name
          source
          searchOrder
          listOrder
          detailOrder
          group
          updatedAt
          createdAt
          __typename
        }
        nextToken
        __typename
      }
      properties {
        items {
          tenantId
          id
          menuId
          name
          inputType
          items
          group
          updatedAt
          createdAt
          __typename
        }
        nextToken
        __typename
      }
      group
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const deleteMenu = /* GraphQL */ `
  mutation DeleteMenu(
    $input: DeleteMenuInput!
    $condition: ModelMenuConditionInput
  ) {
    deleteMenu(input: $input, condition: $condition) {
      tenantId
      id
      showOrder
      name
      icon
      functionId
      stockStatus
      jobStep
      locationFlag
      sendFinishFlag
      unexpectedTagFlag
      mapMode
      pickingRssi
      settings
      showDeleteButton
      showSide
      configs {
        items {
          tenantId
          id
          menuId
          name
          source
          searchOrder
          listOrder
          detailOrder
          group
          updatedAt
          createdAt
          __typename
        }
        nextToken
        __typename
      }
      properties {
        items {
          tenantId
          id
          menuId
          name
          inputType
          items
          group
          updatedAt
          createdAt
          __typename
        }
        nextToken
        __typename
      }
      group
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const createMenuConfig = /* GraphQL */ `
  mutation CreateMenuConfig(
    $input: CreateMenuConfigInput!
    $condition: ModelMenuConfigConditionInput
  ) {
    createMenuConfig(input: $input, condition: $condition) {
      tenantId
      id
      menuId
      name
      source
      sourceDefine {
        tenantId
        source
        name
        inputType
        items
        sourceTable
        showOnApp
        searchOnApp
        order
        group
        updatedAt
        createdAt
        __typename
      }
      searchOrder
      listOrder
      detailOrder
      group
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const updateMenuConfig = /* GraphQL */ `
  mutation UpdateMenuConfig(
    $input: UpdateMenuConfigInput!
    $condition: ModelMenuConfigConditionInput
  ) {
    updateMenuConfig(input: $input, condition: $condition) {
      tenantId
      id
      menuId
      name
      source
      sourceDefine {
        tenantId
        source
        name
        inputType
        items
        sourceTable
        showOnApp
        searchOnApp
        order
        group
        updatedAt
        createdAt
        __typename
      }
      searchOrder
      listOrder
      detailOrder
      group
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const deleteMenuConfig = /* GraphQL */ `
  mutation DeleteMenuConfig(
    $input: DeleteMenuConfigInput!
    $condition: ModelMenuConfigConditionInput
  ) {
    deleteMenuConfig(input: $input, condition: $condition) {
      tenantId
      id
      menuId
      name
      source
      sourceDefine {
        tenantId
        source
        name
        inputType
        items
        sourceTable
        showOnApp
        searchOnApp
        order
        group
        updatedAt
        createdAt
        __typename
      }
      searchOrder
      listOrder
      detailOrder
      group
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const createMenuJobProperty = /* GraphQL */ `
  mutation CreateMenuJobProperty(
    $input: CreateMenuJobPropertyInput!
    $condition: ModelMenuJobPropertyConditionInput
  ) {
    createMenuJobProperty(input: $input, condition: $condition) {
      tenantId
      id
      menuId
      name
      inputType
      items
      group
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const updateMenuJobProperty = /* GraphQL */ `
  mutation UpdateMenuJobProperty(
    $input: UpdateMenuJobPropertyInput!
    $condition: ModelMenuJobPropertyConditionInput
  ) {
    updateMenuJobProperty(input: $input, condition: $condition) {
      tenantId
      id
      menuId
      name
      inputType
      items
      group
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const deleteMenuJobProperty = /* GraphQL */ `
  mutation DeleteMenuJobProperty(
    $input: DeleteMenuJobPropertyInput!
    $condition: ModelMenuJobPropertyConditionInput
  ) {
    deleteMenuJobProperty(input: $input, condition: $condition) {
      tenantId
      id
      menuId
      name
      inputType
      items
      group
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const createWarehouse = /* GraphQL */ `
  mutation CreateWarehouse(
    $input: CreateWarehouseInput!
    $condition: ModelWarehouseConditionInput
  ) {
    createWarehouse(input: $input, condition: $condition) {
      tenantId
      id
      name
      type
      width
      height
      real_width
      real_height
      default_shelf_height
      default_shelf_width
      rssi_threshhold
      state_change_interval
      all_shelves_scan_percentage
      remarks
      location_tag_filter
      item_tag_filter
      upload
      svg
      locations {
        items {
          tenantId
          id
          warehouseId
          x
          y
          width
          height
          rotation
          label
          number
          group
          updatedAt
          createdAt
          __typename
        }
        nextToken
        __typename
      }
      tags {
        items {
          tenantId
          id
          warehouseId
          location_id
          item_id
          epc
          x
          y
          group
          updatedAt
          createdAt
          __typename
        }
        nextToken
        __typename
      }
      group
      robot_home_x
      robot_home_y
      robot_stop_meters
      robot_stop_seconds
      robot_distance
      robot_orientation
      shelf_transparency
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const updateWarehouse = /* GraphQL */ `
  mutation UpdateWarehouse(
    $input: UpdateWarehouseInput!
    $condition: ModelWarehouseConditionInput
  ) {
    updateWarehouse(input: $input, condition: $condition) {
      tenantId
      id
      name
      type
      width
      height
      real_width
      real_height
      default_shelf_height
      default_shelf_width
      rssi_threshhold
      state_change_interval
      all_shelves_scan_percentage
      remarks
      location_tag_filter
      item_tag_filter
      upload
      svg
      locations {
        items {
          tenantId
          id
          warehouseId
          x
          y
          width
          height
          rotation
          label
          number
          group
          updatedAt
          createdAt
          __typename
        }
        nextToken
        __typename
      }
      tags {
        items {
          tenantId
          id
          warehouseId
          location_id
          item_id
          epc
          x
          y
          group
          updatedAt
          createdAt
          __typename
        }
        nextToken
        __typename
      }
      group
      robot_home_x
      robot_home_y
      robot_stop_meters
      robot_stop_seconds
      robot_distance
      robot_orientation
      shelf_transparency
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const deleteWarehouse = /* GraphQL */ `
  mutation DeleteWarehouse(
    $input: DeleteWarehouseInput!
    $condition: ModelWarehouseConditionInput
  ) {
    deleteWarehouse(input: $input, condition: $condition) {
      tenantId
      id
      name
      type
      width
      height
      real_width
      real_height
      default_shelf_height
      default_shelf_width
      rssi_threshhold
      state_change_interval
      all_shelves_scan_percentage
      remarks
      location_tag_filter
      item_tag_filter
      upload
      svg
      locations {
        items {
          tenantId
          id
          warehouseId
          x
          y
          width
          height
          rotation
          label
          number
          group
          updatedAt
          createdAt
          __typename
        }
        nextToken
        __typename
      }
      tags {
        items {
          tenantId
          id
          warehouseId
          location_id
          item_id
          epc
          x
          y
          group
          updatedAt
          createdAt
          __typename
        }
        nextToken
        __typename
      }
      group
      robot_home_x
      robot_home_y
      robot_stop_meters
      robot_stop_seconds
      robot_distance
      robot_orientation
      shelf_transparency
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const createLocation = /* GraphQL */ `
  mutation CreateLocation(
    $input: CreateLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    createLocation(input: $input, condition: $condition) {
      tenantId
      id
      warehouseId
      x
      y
      width
      height
      rotation
      label
      number
      group
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const updateLocation = /* GraphQL */ `
  mutation UpdateLocation(
    $input: UpdateLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    updateLocation(input: $input, condition: $condition) {
      tenantId
      id
      warehouseId
      x
      y
      width
      height
      rotation
      label
      number
      group
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const deleteLocation = /* GraphQL */ `
  mutation DeleteLocation(
    $input: DeleteLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    deleteLocation(input: $input, condition: $condition) {
      tenantId
      id
      warehouseId
      x
      y
      width
      height
      rotation
      label
      number
      group
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const createLocationTag = /* GraphQL */ `
  mutation CreateLocationTag(
    $input: CreateLocationTagInput!
    $condition: ModelLocationTagConditionInput
  ) {
    createLocationTag(input: $input, condition: $condition) {
      tenantId
      id
      warehouseId
      location_id
      item_id
      epc
      x
      y
      group
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const updateLocationTag = /* GraphQL */ `
  mutation UpdateLocationTag(
    $input: UpdateLocationTagInput!
    $condition: ModelLocationTagConditionInput
  ) {
    updateLocationTag(input: $input, condition: $condition) {
      tenantId
      id
      warehouseId
      location_id
      item_id
      epc
      x
      y
      group
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const deleteLocationTag = /* GraphQL */ `
  mutation DeleteLocationTag(
    $input: DeleteLocationTagInput!
    $condition: ModelLocationTagConditionInput
  ) {
    deleteLocationTag(input: $input, condition: $condition) {
      tenantId
      id
      warehouseId
      location_id
      item_id
      epc
      x
      y
      group
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const createTenant = /* GraphQL */ `
  mutation CreateTenant(
    $input: CreateTenantInput!
    $condition: ModelTenantConditionInput
  ) {
    createTenant(input: $input, condition: $condition) {
      tenantId
      remarks
      encode_type
      location_tag_filter
      item_tag_filter
      duplicate_itemCode
      group
      systemId
      ip_check
      show_all_warehouse
      tecTagFlag
      barcode_input_all_flag
      additional_filter
      customer_masters
      indoor_code
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const updateTenant = /* GraphQL */ `
  mutation UpdateTenant(
    $input: UpdateTenantInput!
    $condition: ModelTenantConditionInput
  ) {
    updateTenant(input: $input, condition: $condition) {
      tenantId
      remarks
      encode_type
      location_tag_filter
      item_tag_filter
      duplicate_itemCode
      group
      systemId
      ip_check
      show_all_warehouse
      tecTagFlag
      barcode_input_all_flag
      additional_filter
      customer_masters
      indoor_code
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const deleteTenant = /* GraphQL */ `
  mutation DeleteTenant(
    $input: DeleteTenantInput!
    $condition: ModelTenantConditionInput
  ) {
    deleteTenant(input: $input, condition: $condition) {
      tenantId
      remarks
      encode_type
      location_tag_filter
      item_tag_filter
      duplicate_itemCode
      group
      systemId
      ip_check
      show_all_warehouse
      tecTagFlag
      barcode_input_all_flag
      additional_filter
      customer_masters
      indoor_code
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const createProcessJob = /* GraphQL */ `
  mutation CreateProcessJob(
    $input: CreateProcessJobInput!
    $condition: ModelProcessJobConditionInput
  ) {
    createProcessJob(input: $input, condition: $condition) {
      tenantId
      jobKey
      status
      seconds
      group
      message
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateProcessJob = /* GraphQL */ `
  mutation UpdateProcessJob(
    $input: UpdateProcessJobInput!
    $condition: ModelProcessJobConditionInput
  ) {
    updateProcessJob(input: $input, condition: $condition) {
      tenantId
      jobKey
      status
      seconds
      group
      message
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteProcessJob = /* GraphQL */ `
  mutation DeleteProcessJob(
    $input: DeleteProcessJobInput!
    $condition: ModelProcessJobConditionInput
  ) {
    deleteProcessJob(input: $input, condition: $condition) {
      tenantId
      jobKey
      status
      seconds
      group
      message
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDataMonitor = /* GraphQL */ `
  mutation CreateDataMonitor(
    $input: CreateDataMonitorInput!
    $condition: ModelDataMonitorConditionInput
  ) {
    createDataMonitor(input: $input, condition: $condition) {
      tenantId
      type
      changedType
      id
      itemCode
      group
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDataMonitor = /* GraphQL */ `
  mutation UpdateDataMonitor(
    $input: UpdateDataMonitorInput!
    $condition: ModelDataMonitorConditionInput
  ) {
    updateDataMonitor(input: $input, condition: $condition) {
      tenantId
      type
      changedType
      id
      itemCode
      group
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDataMonitor = /* GraphQL */ `
  mutation DeleteDataMonitor(
    $input: DeleteDataMonitorInput!
    $condition: ModelDataMonitorConditionInput
  ) {
    deleteDataMonitor(input: $input, condition: $condition) {
      tenantId
      type
      changedType
      id
      itemCode
      group
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAutoNumber = /* GraphQL */ `
  mutation CreateAutoNumber(
    $input: CreateAutoNumberInput!
    $condition: ModelAutoNumberConditionInput
  ) {
    createAutoNumber(input: $input, condition: $condition) {
      tenantId
      numberKey
      number
      group
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAutoNumber = /* GraphQL */ `
  mutation UpdateAutoNumber(
    $input: UpdateAutoNumberInput!
    $condition: ModelAutoNumberConditionInput
  ) {
    updateAutoNumber(input: $input, condition: $condition) {
      tenantId
      numberKey
      number
      group
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAutoNumber = /* GraphQL */ `
  mutation DeleteAutoNumber(
    $input: DeleteAutoNumberInput!
    $condition: ModelAutoNumberConditionInput
  ) {
    deleteAutoNumber(input: $input, condition: $condition) {
      tenantId
      numberKey
      number
      group
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEpcPublish = /* GraphQL */ `
  mutation CreateEpcPublish(
    $input: CreateEpcPublishInput!
    $condition: ModelEpcPublishConditionInput
  ) {
    createEpcPublish(input: $input, condition: $condition) {
      tenantId
      publishKey
      itemCodeNum
      serialNum
      group
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEpcPublish = /* GraphQL */ `
  mutation UpdateEpcPublish(
    $input: UpdateEpcPublishInput!
    $condition: ModelEpcPublishConditionInput
  ) {
    updateEpcPublish(input: $input, condition: $condition) {
      tenantId
      publishKey
      itemCodeNum
      serialNum
      group
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEpcPublish = /* GraphQL */ `
  mutation DeleteEpcPublish(
    $input: DeleteEpcPublishInput!
    $condition: ModelEpcPublishConditionInput
  ) {
    deleteEpcPublish(input: $input, condition: $condition) {
      tenantId
      publishKey
      itemCodeNum
      serialNum
      group
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateStock = /* GraphQL */ `
  mutation UpdateStock($stockList: [StockInput]) {
    updateStock(stockList: $stockList)
  }
`;
export const addStock = /* GraphQL */ `
  mutation AddStock($stock: AWSJSON) {
    addStock(stock: $stock)
  }
`;
export const deleteGroup = /* GraphQL */ `
  mutation DeleteGroup($groupname: String) {
    deleteGroup(groupname: $groupname)
  }
`;
