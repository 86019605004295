/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listJobs = /* GraphQL */ `
  query ListJobs($conditions: AWSJSON) {
    listJobs(conditions: $conditions)
  }
`;
export const listJobDetails = /* GraphQL */ `
  query ListJobDetails($conditions: AWSJSON) {
    listJobDetails(conditions: $conditions)
  }
`;
export const listJobEpcs = /* GraphQL */ `
  query ListJobEpcs($conditions: AWSJSON) {
    listJobEpcs(conditions: $conditions)
  }
`;
export const getJobSummary = /* GraphQL */ `
  query GetJobSummary($warehouseId: Int) {
    getJobSummary(warehouseId: $warehouseId) {
      menuId
      count
      __typename
    }
  }
`;
export const getJobList = /* GraphQL */ `
  query GetJobList($warehouseId: Int, $menuId: Int) {
    getJobList(warehouseId: $warehouseId, menuId: $menuId) {
      id
      expectAmount
      actualAmount
      properties
      menuId
      warehouseId
      __typename
    }
  }
`;
export const getJobDetailList = /* GraphQL */ `
  query GetJobDetailList(
    $jobId: Int
    $flag: Boolean
    $offset: Int
    $limit: Int
  ) {
    getJobDetailList(
      jobId: $jobId
      flag: $flag
      offset: $offset
      limit: $limit
    ) {
      id
      itemCode
      expectAmount
      epcs {
        epc
        itemCode
        serial
        status
        locationId
        readTime
        updatedAt
        jobId
        __typename
      }
      itemMaster
      locationIds
      jobId
      __typename
    }
  }
`;
export const getJobEpcList = /* GraphQL */ `
  query GetJobEpcList($conditions: AWSJSON) {
    getJobEpcList(conditions: $conditions) {
      epc
      itemCode
      serial
      status
      locationId
      readTime
      updatedAt
      jobId
      __typename
    }
  }
`;
export const getStockDetailList = /* GraphQL */ `
  query GetStockDetailList($conditions: AWSJSON) {
    getStockDetailList(conditions: $conditions) {
      epc
      itemCode
      serial
      status
      locationId
      readTime
      updatedAt
      jobId
      __typename
    }
  }
`;
export const searchStocks = /* GraphQL */ `
  query SearchStocks($conditions: AWSJSON) {
    searchStocks(conditions: $conditions)
  }
`;
export const getStockAmount = /* GraphQL */ `
  query GetStockAmount($conditions: AWSJSON) {
    getStockAmount(conditions: $conditions)
  }
`;
export const searchJobs = /* GraphQL */ `
  query SearchJobs($conditions: AWSJSON) {
    searchJobs(conditions: $conditions)
  }
`;
export const searchAllStocks = /* GraphQL */ `
  query SearchAllStocks($conditions: AWSJSON) {
    searchAllStocks(conditions: $conditions)
  }
`;
export const searchHistory = /* GraphQL */ `
  query SearchHistory($conditions: AWSJSON) {
    searchHistory(conditions: $conditions)
  }
`;
export const getItemMasterList = /* GraphQL */ `
  query GetItemMasterList($conditions: AWSJSON) {
    getItemMasterList(conditions: $conditions)
  }
`;
export const getStockLocation = /* GraphQL */ `
  query GetStockLocation($conditions: AWSJSON) {
    getStockLocation(conditions: $conditions) {
      itemCode
      locationId
      __typename
    }
  }
`;
export const checkIp = /* GraphQL */ `
  query CheckIp {
    checkIp
  }
`;
export const getAddableItems = /* GraphQL */ `
  query GetAddableItems($tableName: String) {
    getAddableItems(tableName: $tableName)
  }
`;
export const listUsersInGroup = /* GraphQL */ `
  query ListUsersInGroup($group: String) {
    listUsersInGroup(group: $group) {
      username
      email
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const getCustomerMaster = /* GraphQL */ `
  query GetCustomerMaster($tableName: String) {
    getCustomerMaster(tableName: $tableName)
  }
`;
export const searchCustomerJobs = /* GraphQL */ `
  query SearchCustomerJobs($conditions: AWSJSON) {
    searchCustomerJobs(conditions: $conditions)
  }
`;
export const getItemsCount = /* GraphQL */ `
  query GetItemsCount($tableName: String, $conditions: AWSJSON) {
    getItemsCount(tableName: $tableName, conditions: $conditions)
  }
`;
export const getItemDefine = /* GraphQL */ `
  query GetItemDefine($tenantId: ID!, $source: String!) {
    getItemDefine(tenantId: $tenantId, source: $source) {
      tenantId
      source
      name
      inputType
      items
      sourceTable
      showOnApp
      searchOnApp
      order
      group
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const listItemDefines = /* GraphQL */ `
  query ListItemDefines(
    $tenantId: ID
    $source: ModelStringKeyConditionInput
    $filter: ModelItemDefineFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listItemDefines(
      tenantId: $tenantId
      source: $source
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        tenantId
        source
        name
        inputType
        items
        sourceTable
        showOnApp
        searchOnApp
        order
        group
        updatedAt
        createdAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const queryItemDefines = /* GraphQL */ `
  query QueryItemDefines(
    $tenantId: ID!
    $sourceTable: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelItemDefineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryItemDefines(
      tenantId: $tenantId
      sourceTable: $sourceTable
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        tenantId
        source
        name
        inputType
        items
        sourceTable
        showOnApp
        searchOnApp
        order
        group
        updatedAt
        createdAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserManage = /* GraphQL */ `
  query GetUserManage($tenantId: ID!, $username: String!) {
    getUserManage(tenantId: $tenantId, username: $username) {
      tenantId
      username
      menuId
      accessLevel
      group
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUserManages = /* GraphQL */ `
  query ListUserManages(
    $tenantId: ID
    $username: ModelStringKeyConditionInput
    $filter: ModelUserManageFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserManages(
      tenantId: $tenantId
      username: $username
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        tenantId
        username
        menuId
        accessLevel
        group
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAppItemConfig = /* GraphQL */ `
  query GetAppItemConfig($tenantId: ID!, $id: ID!) {
    getAppItemConfig(tenantId: $tenantId, id: $id) {
      tenantId
      id
      screenId
      source
      name
      sourceDefine {
        tenantId
        source
        name
        inputType
        items
        sourceTable
        showOnApp
        searchOnApp
        order
        group
        updatedAt
        createdAt
        __typename
      }
      order
      group
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const listAppItemConfigs = /* GraphQL */ `
  query ListAppItemConfigs(
    $tenantId: ID
    $id: ModelIDKeyConditionInput
    $filter: ModelAppItemConfigFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAppItemConfigs(
      tenantId: $tenantId
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        tenantId
        id
        screenId
        source
        name
        sourceDefine {
          tenantId
          source
          name
          inputType
          items
          sourceTable
          showOnApp
          searchOnApp
          order
          group
          updatedAt
          createdAt
          __typename
        }
        order
        group
        updatedAt
        createdAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const queryAppItemConfigs = /* GraphQL */ `
  query QueryAppItemConfigs(
    $tenantId: ID!
    $screenId: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAppItemConfigFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryAppItemConfigs(
      tenantId: $tenantId
      screenId: $screenId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        tenantId
        id
        screenId
        source
        name
        sourceDefine {
          tenantId
          source
          name
          inputType
          items
          sourceTable
          showOnApp
          searchOnApp
          order
          group
          updatedAt
          createdAt
          __typename
        }
        order
        group
        updatedAt
        createdAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMenu = /* GraphQL */ `
  query GetMenu($tenantId: ID!, $id: Int!) {
    getMenu(tenantId: $tenantId, id: $id) {
      tenantId
      id
      showOrder
      name
      icon
      functionId
      stockStatus
      jobStep
      locationFlag
      sendFinishFlag
      unexpectedTagFlag
      mapMode
      pickingRssi
      settings
      showDeleteButton
      showSide
      configs {
        items {
          tenantId
          id
          menuId
          name
          source
          searchOrder
          listOrder
          detailOrder
          group
          updatedAt
          createdAt
          __typename
        }
        nextToken
        __typename
      }
      properties {
        items {
          tenantId
          id
          menuId
          name
          inputType
          items
          group
          updatedAt
          createdAt
          __typename
        }
        nextToken
        __typename
      }
      group
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const listMenus = /* GraphQL */ `
  query ListMenus(
    $tenantId: ID
    $id: ModelIntKeyConditionInput
    $filter: ModelMenuFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMenus(
      tenantId: $tenantId
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        tenantId
        id
        showOrder
        name
        icon
        functionId
        stockStatus
        jobStep
        locationFlag
        sendFinishFlag
        unexpectedTagFlag
        mapMode
        pickingRssi
        settings
        showDeleteButton
        showSide
        configs {
          nextToken
          __typename
        }
        properties {
          nextToken
          __typename
        }
        group
        updatedAt
        createdAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMenuConfig = /* GraphQL */ `
  query GetMenuConfig($tenantId: ID!, $id: ID!) {
    getMenuConfig(tenantId: $tenantId, id: $id) {
      tenantId
      id
      menuId
      name
      source
      sourceDefine {
        tenantId
        source
        name
        inputType
        items
        sourceTable
        showOnApp
        searchOnApp
        order
        group
        updatedAt
        createdAt
        __typename
      }
      searchOrder
      listOrder
      detailOrder
      group
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const listMenuConfigs = /* GraphQL */ `
  query ListMenuConfigs(
    $tenantId: ID
    $id: ModelIDKeyConditionInput
    $filter: ModelMenuConfigFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMenuConfigs(
      tenantId: $tenantId
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        tenantId
        id
        menuId
        name
        source
        sourceDefine {
          tenantId
          source
          name
          inputType
          items
          sourceTable
          showOnApp
          searchOnApp
          order
          group
          updatedAt
          createdAt
          __typename
        }
        searchOrder
        listOrder
        detailOrder
        group
        updatedAt
        createdAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const queryMenuConfigsByMenuId = /* GraphQL */ `
  query QueryMenuConfigsByMenuId(
    $tenantId: ID!
    $menuId: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMenuConfigFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryMenuConfigsByMenuId(
      tenantId: $tenantId
      menuId: $menuId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        tenantId
        id
        menuId
        name
        source
        sourceDefine {
          tenantId
          source
          name
          inputType
          items
          sourceTable
          showOnApp
          searchOnApp
          order
          group
          updatedAt
          createdAt
          __typename
        }
        searchOrder
        listOrder
        detailOrder
        group
        updatedAt
        createdAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMenuJobProperty = /* GraphQL */ `
  query GetMenuJobProperty($tenantId: ID!, $id: Int!) {
    getMenuJobProperty(tenantId: $tenantId, id: $id) {
      tenantId
      id
      menuId
      name
      inputType
      items
      group
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const listMenuJobProperties = /* GraphQL */ `
  query ListMenuJobProperties(
    $tenantId: ID
    $id: ModelIntKeyConditionInput
    $filter: ModelMenuJobPropertyFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMenuJobProperties(
      tenantId: $tenantId
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        tenantId
        id
        menuId
        name
        inputType
        items
        group
        updatedAt
        createdAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const queryJobPropertyByMenuId = /* GraphQL */ `
  query QueryJobPropertyByMenuId(
    $tenantId: ID!
    $menuId: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMenuJobPropertyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryJobPropertyByMenuId(
      tenantId: $tenantId
      menuId: $menuId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        tenantId
        id
        menuId
        name
        inputType
        items
        group
        updatedAt
        createdAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getWarehouse = /* GraphQL */ `
  query GetWarehouse($tenantId: ID!, $id: Int!) {
    getWarehouse(tenantId: $tenantId, id: $id) {
      tenantId
      id
      name
      type
      width
      height
      real_width
      real_height
      default_shelf_height
      default_shelf_width
      rssi_threshhold
      state_change_interval
      all_shelves_scan_percentage
      remarks
      location_tag_filter
      item_tag_filter
      upload
      svg
      locations {
        items {
          tenantId
          id
          warehouseId
          x
          y
          width
          height
          rotation
          label
          number
          group
          updatedAt
          createdAt
          __typename
        }
        nextToken
        __typename
      }
      tags {
        items {
          tenantId
          id
          warehouseId
          location_id
          item_id
          epc
          x
          y
          group
          updatedAt
          createdAt
          __typename
        }
        nextToken
        __typename
      }
      group
      robot_home_x
      robot_home_y
      robot_stop_meters
      robot_stop_seconds
      robot_distance
      robot_orientation
      shelf_transparency
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const listWarehouses = /* GraphQL */ `
  query ListWarehouses(
    $tenantId: ID
    $id: ModelIntKeyConditionInput
    $filter: ModelWarehouseFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listWarehouses(
      tenantId: $tenantId
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        tenantId
        id
        name
        type
        width
        height
        real_width
        real_height
        default_shelf_height
        default_shelf_width
        rssi_threshhold
        state_change_interval
        all_shelves_scan_percentage
        remarks
        location_tag_filter
        item_tag_filter
        upload
        svg
        locations {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        group
        robot_home_x
        robot_home_y
        robot_stop_meters
        robot_stop_seconds
        robot_distance
        robot_orientation
        shelf_transparency
        updatedAt
        createdAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLocation = /* GraphQL */ `
  query GetLocation($tenantId: ID!, $id: Int!) {
    getLocation(tenantId: $tenantId, id: $id) {
      tenantId
      id
      warehouseId
      x
      y
      width
      height
      rotation
      label
      number
      group
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const listLocations = /* GraphQL */ `
  query ListLocations(
    $tenantId: ID
    $id: ModelIntKeyConditionInput
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listLocations(
      tenantId: $tenantId
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        tenantId
        id
        warehouseId
        x
        y
        width
        height
        rotation
        label
        number
        group
        updatedAt
        createdAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const queryLocationsByWarehouseId = /* GraphQL */ `
  query QueryLocationsByWarehouseId(
    $tenantId: ID!
    $warehouseId: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryLocationsByWarehouseId(
      tenantId: $tenantId
      warehouseId: $warehouseId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        tenantId
        id
        warehouseId
        x
        y
        width
        height
        rotation
        label
        number
        group
        updatedAt
        createdAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const queryLocations = /* GraphQL */ `
  query QueryLocations(
    $tenantId: ID!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryLocations(
      tenantId: $tenantId
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        tenantId
        id
        warehouseId
        x
        y
        width
        height
        rotation
        label
        number
        group
        updatedAt
        createdAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLocationTag = /* GraphQL */ `
  query GetLocationTag($tenantId: ID!, $id: Int!) {
    getLocationTag(tenantId: $tenantId, id: $id) {
      tenantId
      id
      warehouseId
      location_id
      item_id
      epc
      x
      y
      group
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const listLocationTags = /* GraphQL */ `
  query ListLocationTags(
    $tenantId: ID
    $id: ModelIntKeyConditionInput
    $filter: ModelLocationTagFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listLocationTags(
      tenantId: $tenantId
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        tenantId
        id
        warehouseId
        location_id
        item_id
        epc
        x
        y
        group
        updatedAt
        createdAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const queryLocationTagsByWarehouseId = /* GraphQL */ `
  query QueryLocationTagsByWarehouseId(
    $tenantId: ID!
    $warehouseId: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLocationTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryLocationTagsByWarehouseId(
      tenantId: $tenantId
      warehouseId: $warehouseId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        tenantId
        id
        warehouseId
        location_id
        item_id
        epc
        x
        y
        group
        updatedAt
        createdAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const queryLocationTags = /* GraphQL */ `
  query QueryLocationTags(
    $tenantId: ID!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLocationTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryLocationTags(
      tenantId: $tenantId
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        tenantId
        id
        warehouseId
        location_id
        item_id
        epc
        x
        y
        group
        updatedAt
        createdAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTenant = /* GraphQL */ `
  query GetTenant($tenantId: ID!) {
    getTenant(tenantId: $tenantId) {
      tenantId
      remarks
      encode_type
      location_tag_filter
      item_tag_filter
      duplicate_itemCode
      group
      systemId
      ip_check
      show_all_warehouse
      tecTagFlag
      barcode_input_all_flag
      additional_filter
      customer_masters
      indoor_code
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const listTenants = /* GraphQL */ `
  query ListTenants(
    $tenantId: ID
    $filter: ModelTenantFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTenants(
      tenantId: $tenantId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        tenantId
        remarks
        encode_type
        location_tag_filter
        item_tag_filter
        duplicate_itemCode
        group
        systemId
        ip_check
        show_all_warehouse
        tecTagFlag
        barcode_input_all_flag
        additional_filter
        customer_masters
        indoor_code
        updatedAt
        createdAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTenantBySystemId = /* GraphQL */ `
  query GetTenantBySystemId(
    $systemId: Int!
    $sortDirection: ModelSortDirection
    $filter: ModelTenantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTenantBySystemId(
      systemId: $systemId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        tenantId
        remarks
        encode_type
        location_tag_filter
        item_tag_filter
        duplicate_itemCode
        group
        systemId
        ip_check
        show_all_warehouse
        tecTagFlag
        barcode_input_all_flag
        additional_filter
        customer_masters
        indoor_code
        updatedAt
        createdAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProcessJob = /* GraphQL */ `
  query GetProcessJob($tenantId: ID!, $jobKey: String!) {
    getProcessJob(tenantId: $tenantId, jobKey: $jobKey) {
      tenantId
      jobKey
      status
      seconds
      group
      message
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listProcessJobs = /* GraphQL */ `
  query ListProcessJobs(
    $tenantId: ID
    $jobKey: ModelStringKeyConditionInput
    $filter: ModelProcessJobFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listProcessJobs(
      tenantId: $tenantId
      jobKey: $jobKey
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        tenantId
        jobKey
        status
        seconds
        group
        message
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDataMonitor = /* GraphQL */ `
  query GetDataMonitor($tenantId: ID!, $createdAt: AWSTimestamp!) {
    getDataMonitor(tenantId: $tenantId, createdAt: $createdAt) {
      tenantId
      type
      changedType
      id
      itemCode
      group
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDataMonitors = /* GraphQL */ `
  query ListDataMonitors(
    $tenantId: ID
    $createdAt: ModelIntKeyConditionInput
    $filter: ModelDataMonitorFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDataMonitors(
      tenantId: $tenantId
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        tenantId
        type
        changedType
        id
        itemCode
        group
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAutoNumber = /* GraphQL */ `
  query GetAutoNumber($tenantId: ID!, $numberKey: String!) {
    getAutoNumber(tenantId: $tenantId, numberKey: $numberKey) {
      tenantId
      numberKey
      number
      group
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAutoNumbers = /* GraphQL */ `
  query ListAutoNumbers(
    $tenantId: ID
    $numberKey: ModelStringKeyConditionInput
    $filter: ModelAutoNumberFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAutoNumbers(
      tenantId: $tenantId
      numberKey: $numberKey
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        tenantId
        numberKey
        number
        group
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEpcPublish = /* GraphQL */ `
  query GetEpcPublish($tenantId: ID!, $publishKey: String!) {
    getEpcPublish(tenantId: $tenantId, publishKey: $publishKey) {
      tenantId
      publishKey
      itemCodeNum
      serialNum
      group
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEpcPublishes = /* GraphQL */ `
  query ListEpcPublishes(
    $tenantId: ID
    $publishKey: ModelStringKeyConditionInput
    $filter: ModelEpcPublishFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEpcPublishes(
      tenantId: $tenantId
      publishKey: $publishKey
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        tenantId
        publishKey
        itemCodeNum
        serialNum
        group
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getStockList = /* GraphQL */ `
  query GetStockList($conditions: AWSJSON) {
    getStockList(conditions: $conditions) {
      epc
      itemCode
      serial
      status
      locationId
      readTime
      updatedAt
      jobId
      __typename
    }
  }
`;
export const listGroupsAndUsers = /* GraphQL */ `
  query ListGroupsAndUsers {
    listGroupsAndUsers {
      groupname
      createdAt
      updatedAt
      description
      users {
        username
        email
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
